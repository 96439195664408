import React, { useEffect } from "react"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import Logo from "../images/logo-new2.png"
import { Link, graphql } from "gatsby"
import { INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SEO from "../components/seo"
import Reveal from "react-reveal"
import styled from "styled-components"

const website_url = "https://youchangeearth.org"
const options = {
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderNode: {
    [INLINES.HYPERLINK]: node => {
      return (
        <a
          href={node.data.uri}
          target={`${
            node.data.uri.startsWith(website_url) ? "_self" : "_blank"
          }`}
          rel={`${
            node.data.uri.startsWith(website_url) ? "" : "noopener noreferrer"
          }`}
        >
          {node.content[0].value}
        </a>
      )
    },
  },
}

const FlexBasisDiv = styled.div`
  flex-basis: 100%;
  @media (min-width: 593px) {
    flex-basis: auto;
  }
`

const StartButton = styled.button``

const LandingPage = props => {
  const company = props.data.contentfulLandingPageItem
  const { backgroundImage } = props.data.contentfulLandingPage
  const { header, description } = props.pageContext
  const logoHeight = 32
  const logoWidth = 36 * company.logo.fluid.aspectRatio
  useEffect(() => {
    console.log(
      window.Intercom("update", {
        hide_default_launcher: true,
      })
    )
    return () => {
      window.Intercom("update", {
        hide_default_launcher: false,
      })
    }
  }, [])
  return (
    // <BackgroundImage fluid={backgroundImage.fluid}>
    <div
      style={{
        backgroundImage: "linear-gradient(to bottom right, #fb6191, #efb851",
      }}
    >
      <SEO title={company.name} />
      <Reveal>
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            flexFlow: "row wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              textAlign: "center",
              color: "white",
              marginTop: "1rem",
            }}
          >
            <div
              style={{
                marginBottom: "2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexFlow: "row wrap",
              }}
            >
              <FlexBasisDiv
                style={{
                  display: "flex",
                  flexFlow: "row wrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Logo}
                  alt="Logo"
                  style={{
                    height: `${logoHeight}px`,
                    width: `${logoHeight}px`,
                    display: "inline",
                    marginRight: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                />
                <h4
                  style={{
                    display: "inline",
                    fontSize: "1.5rem",
                    fontWeight: 500,
                    marginBottom: "0.5rem",
                  }}
                >
                  You Change Earth
                </h4>
              </FlexBasisDiv>
              <FlexBasisDiv>
                <p
                  style={{
                    margin: "0 2rem",
                    fontSize: "1.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  x
                </p>
              </FlexBasisDiv>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row wrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Img
                  fluid={company.logo.fluid}
                  style={{
                    height: `${logoHeight + 5}px`,
                    width: `${logoWidth}px`,
                    marginRight: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                  alt="Company logo"
                />
                {company.name && (
                  <h4
                    style={{
                      display: "inline",
                      fontSize: "1.5rem",
                      fontWeight: 500,
                      marginBottom: "0.5rem",
                    }}
                  >
                    {company.name}
                  </h4>
                )}
              </div>
            </div>
            <h1 style={{ fontSize: "2rem", fontWeight: 500 }}>{header}</h1>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <p style={{ maxWidth: "400px", margin: "0 1rem" }}>
                {documentToReactComponents(description.json, options)}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Link to="/mainform" style={{ textDecoration: "none" }}>
                <div
                  style={{
                    width: "10rem",
                    marginTop: "1rem",
                    padding: "1rem 1rem",
                    color: "white",
                    textAlign: "center",
                    textDecoration: "none",
                    backgroundColor: "#FA2E72",
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: 400,
                      textDecoration: "none",
                    }}
                  >
                    GET STARTED
                  </p>
                </div>
              </Link>
            </div>
            <div style={{ margin: "2rem 1rem 1rem 1rem" }}>
              <Link
                to="/about"
                style={{
                  // textDecoration: "none",
                  color: "white",
                  fontWeight: "700",
                }}
              >
                <p>Learn more about You Change Earth here</p>
              </Link>
            </div>
          </div>
        </div>
      </Reveal>
    </div>
    // </BackgroundImage>
  )
}

export const query = graphql`
  query specificLandingPage($slug: String) {
    contentfulLandingPageItem(slug: { eq: $slug }) {
      name
      logo {
        fluid {
          aspectRatio
          ...GatsbyContentfulFluid
        }
      }
    }
    contentfulLandingPage {
      backgroundImage {
        fluid(maxWidth: 8000) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`

export default LandingPage
